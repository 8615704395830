<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../../assets/images/logo/stlogo1.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Builder Tile & Flooring</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start Service Area  -->
    <div class="rn-service-area rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-title text-center mb--30">
              <h2>Products</h2>
              <p>
                The possibilities are endless with tile.  Available in ceramic, porcelain, natural stone, mosaics, accent decos and much more, there is no end to what you can do with tile. Tile is durable and easy to maintain, making it the perfect choice of flooring for families with children or pets.
              </p>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <ServiceFour />
      </v-container>
    </div>

    <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="sercice-details-content pb--80 align-items-center"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../../assets/images/suntile/Web.Tile.Image.jpg"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <h1>Ceramic Tile</h1>
                      <p>
                        Ceramic tile is manufactured from clay materials that are quarried, prepared, and then formed into a mold. Common forming methods for ceramic tile include dry press, extruded, and slush mold. The dry press forming method involves a mixture of dry material being pressed into a mold under extreme pressure. Extruded ceramic tile is formed when a mixture of slightly wet material is extruded into a mold. Slush mold is a forming method in which a mixture of very wet material is poured into a mold and then hardened in a kiln at an extremely high temperature.
                      </p>
                      
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-items-center">
                  <v-col lg="12" md="12" cols="12" order="2" order-md="1">
                    <div class="details mt_md--30 mt_sm--30">
                     <h4 class="title">Porcelain vs. Non-Porcelain</h4>
                      <p>
                      Ceramic tile can best be characterized as either porcelain or non-porcelain. Traditional ceramic tile is non-porcelain and is made from white, red, and/or brown clay and other minerals. Porcelain ceramic tile is made from clay and minerals as well, but it also contains 50% of a white dust or sand called feldspar. Feldspar is a type of crystal found in rock that acts as a "flux" during the kiln-drying process, melting into a glass-like material and bonding all of the molded ingredients together. Minor modifications to the ingredients of ceramic tile or the kiln-drying process (i.e., to the temperature and type of kiln) create enormous variety in the appearance and characteristics of manufactured ceramic tile flooring products.
                      </p>
                      <p>
                      Porcelain and non-porcelain ceramic tile can be either unglazed or glazed. Glazed tile has a matte, semi-gloss, or high-gloss finish applied to the surface during the manufacturing process. In the past, glazed tile was kiln-fired twice, once to harden the tile mold and a second time to harden the glaze. Today, in addition to double-fired ceramic tiles, an automated single-fired manufacturing process called Monocuttura hardens a glazed mold in one step. Glazed tiles have increased stain resistance, scratch resistance, and traction, as well as decreased water absorption, in comparison to an unglazed tile.
                        Non-porcelain, ceramic tile is among the most economical types of tile flooring. Porcelain ceramic flooring is more expensive than non-porcelain and can be harder to work with. However, it offers greater durability, natural stain resistance, minimal water absorption, and through-bodied color. Many types of tile are manufactured in a similar fashion to ceramic tile, but they are less common. These include brick, cement, glass, encaustic, saltillo, and terra cotta tile. The varying materials and manufacturing processes create distinctive product characteristics.
                      </p>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12" order="1" order-md="2">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="items"
                        :index="index"
                        @close="index = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in items"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="About Images"
                          />
                          <a
                            @click="index = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="sercice-details-content pb--80 align-items-center"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../../assets/images/suntile/natural stone.web site.image.jpg"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <h1>Natural Stone</h1>
                      <p>
                        Natural stone tile is produced from natural materials that are quarried, slabbed, finished, and cut to size. Common types of stone used as flooring tile include granite, marble, limestone (including travertine), and slate. Among these types of natural stone are thousands of varieties with characteristics that depend on where and when the stone was quarried.
                      </p>
                      <p>
                        <b>Travertine</b> is a type of limestone that offers an unusual crystallized appearance with an earthy tone. Travertine is a soft, porous stone with a natural surface that has pitting or divots. A honed or polished surface can be achieved after filling the surface voids. Travertine is not recommended for kitchen floors, as it can be easily scratched and stained. Special care and surface sealing is required to maintain travertine.
                        <br/>
                        <b>Limestone</b> is a type of sedimentary rock that offers an earthy appearance in both light and dark shades. The surface can be textured or polished smooth. Limestone is less dense than granite and marble. It can be easily stained and is also prone to scratching. It is not recommended for kitchen or high-traffic flooring applications.
                        <br/>
                        <b>Slate</b> is a type of metamorphic rock that is extremely dense and very durable. Slate is available in darker earthy tones. The surface of slate is naturally textured unless a smooth, honed finish is achieved. Slate is an excellent choice for kitchen and high-traffic area flooring.
                        <br/>
                        <b>Marble</b> is a type of metamorphic rock that has rich veining and is available in a variety of colors. Marble is more porous than granite and is not recommended for kitchen flooring unless honed and then sealed on a regular basis.
                        <br/>
                        <b>Granite</b> is a type of igneous rock that is very dense and hard. Its distinctive appearance is due to speckled minerals found within the rock, its unique veining, and the thousands of available colors. Granite is nearly impervious and, once it is polished, resists scratching. It is an excellent choice for flooring in kitchens and high-traffic areas.

                      </p>
                      
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

           
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

        <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="sercice-details-content pb--80 align-items-center"
                >
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../../assets/images/suntile/Carpet.web.image.jpg"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <h1>Carpet</h1>
                      <p>
                        If you are looking for soft and warm, carpet is the way to go.  Carpet is comfortable to get down on and play, softer on the feet and can absorb sound.  Carpet is available in many different colors, patterns and textures so you’re sure to find something to fit your room and lifestyle.
                      </p>
                      <p>
                        <b>Carpet Flooring Information</b>
                        <br/>
                        Carpet is a type of flooring that is made from woven fiber, and comes in a variety of styles, patterns, and colors. Due to its cushioned surface, carpet absorbs sound, adds additional warmth, and offers you a non-slip surface. Not only is carpet one of the most affordable flooring products, but most are treated with static, stain, and soil resistant treatments, making them incredibly easy to clean and maintain.
                      </p>
                      <ul>
                        <li>Carpet offers amazing softness and beauty to a room.</li>
                        <li>There is a wide variety of colors and patterns to choose from.</li>
                        <li>Carpet is made in a variety of fibers, each one offering different qualities that are suited for your space.</li>
                      </ul>
                      
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

           
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="sercice-details-content pb--80 align-items-center"
                >

                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <h1>Wood Flooring</h1>
                      <p>
                        Hardwood floors are a classic.  Hardwood floors add value to your home and are a “must-have” for many buyers today.  Wood floors add beauty to your home and are easy to maintain.  They come in many different species and finishes.  Hardwood also offers the advantage of not trapping allergens.
                      </p>
                      <p>
                      One of the great things about hardwood flooring is that it never goes out of style. It’s been in use continuously for thousands of years, and despite numerous shifts and changes in style and design, it has survived the test of trends, showing that the look of this material never gets old.
                      </p>
                      <p>
                      This is because hardwood acts as an instant connection between an interior location and the natural world. The feeling of a forest and the essence of a densely thicketed wood can be achieved, in even the most modern mundane environments. Hardwood flooring offers these benefits:
                      </p>
                      <ul>
                        <li>
                        Unique: Every plank, board, or strip used on a hardwood floor is a work of natural art, with unique patterns, and colors. This is compounded by the fact that there are dozens of different species of domestic, imported, common, and rare hardwoods to choose from, each with a particular grain, color, and characteristics. That ensures that each installation of this material is a one-of-a-kind feature.
                        </li>
                        <li>
                        Character: Over time wood is going to change. It will acquire subtle shadings of color, and pick up small scratches, nicks, and dents, which will collect on the surface to create a unique personality for your floor. That is the kind of character that cannot be bought but can only be attained over years.
                        </li>
                        <li>
                          Large spaces: Wood is a particularly good flooring material for large open spaces. That is because the grains and patterns found within its surface will help to break up the monotony of the room, acting as a backdrop, while also working to infuse interest and design into the space.  
                        </li>
                      </ul>
                    </div>
                  </v-col>
                                    <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../../assets/images/suntile/434-Island-Oaks-Lane-26.jpg"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

           
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

     <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="sercice-details-content pb--80 align-items-center"
                >

                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <h1>Vinyl Planks</h1>
                      <p>
                         At its heart, plank vinyl floor, also called luxury vinyl plank (LVP), luxury vinyl floor (LVF), or enhanced vinyl plank (EVP) is simply vinyl flooring that comes in long, narrow strips rather than the traditional square tile shapes. But structurally this is a different product. Sheet vinyl is generally flexible vinyl with a printed top layer covered by a clear wear layer, while vinyl plank flooring is a multi-ply product that features four layers:
                      </p>
                      <ul>
                        <li>
                        A topmost layer of aluminum oxide, designed to prevent light scratching and scuffs
                        </li>
                        <li>
                        A clear film layer that protects against more severe ripping and tearing
                        </li>
                        <li>
                          A design layer that provides the photo-realistic look of wood or stone
                        </li>
                         <li>
                          A backing layer made of fairly rigid vinyl, comprising almost 90 percent of the total thickness of luxury vinyl
                        </li>
                      </ul>
                      <p>
                      Luxury vinyl is therefore almost five times thicker than traditional sheet vinyl, allowing it to be semi-rigid. Rather than being rolled out over the floor and glued down, luxury vinyl planks snap together. Manufacturers of luxury plank flooring offer dozens of different styles to match the distinctive appearance of different wood species—right down to textured surfaces that mimic real wood grain. Plank vinyl floor also tends to have deeper embossing and better graphics, rendering it a closer simulation of wood and stone than previous iterations of sheet vinyl flooring or plastic laminate flooring.
                      You can even find vinyl planks with a heavily antiqued or distressed look, hand-scraped, dinged, scratched, and peppered with nail holes. But these products are more expensive, since the planks need to be quite thick to handle such deeply textured embossing.
                      Vinyl flooring planks typically are 48 or 36 inches long. With most planks, the width is about 6 or 7 inches, though some go as much as 9 inches wide.

                      </p>
                    </div>
                  </v-col>
                                    <v-col lg="6" md="6" cols="12">
                    <div class="thumb">
                      <img
                        class="w-100"
                        src="../../assets/images/suntile/vinylplank.web.image.jpg"
                        alt="Service Images"
                      />
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->

           
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Header from "../../components/header/Header";
  import ServiceThree from "../../components/service/ServiceThree";
  import ServiceFour from "../../components/service/ServiceFour";
  import ServiceTwo from "../../components/service/ServiceTwo";
  import Footer from "../../components/footer/Footer";
  import feather from "feather-icons";
  export default {
    components: {
      Header,
      ServiceThree,
      ServiceFour,
      ServiceTwo,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "Service",
            to: "",
            disabled: true,
          },
        ],
        serviceContent2: [
          {
            icon: "cast",
            title: "Tiles",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "cast",
            title: "Stone",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "cast",
            title: "Carpet",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
        ],
        serviceContent: [
          {
            icon: "cast",
            title: "Wood Flooring",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "layers",
            title: "Website Development",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "users",
            title: "Marketing & Reporting",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "monitor",
            title: "Mobile Development",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "camera",
            title: "Marketing & Reporting",
            desc: `I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
          {
            icon: "activity",
            title: "Mobile Development",
            desc: ` I throw myself down among the tall grass by the stream as I lie
                close to the earth.`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
